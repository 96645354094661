import { Component, BaseComponent, Prop, Inject, namespace } from '@zento-lib/components';
import { AppContextStore, KEY as appKey } from 'theme/@types/zento/stores/applicationContext';

import type { IAccordionItem } from './Item.d';
import style from './style.scss';

const appContextStore = namespace<AppContextStore>(appKey);

@Component({})
export class AccordionItem extends BaseComponent<IAccordionItem, unknown> {
  @appContextStore.Getter('isDesktop')
  private isDesktop: boolean;

  @Prop({ type: Boolean, required: false, default: true })
  hideOnCollapse?: boolean;

  @Prop({ type: Boolean, required: false, default: false })
  productAccordionStyle?: boolean;

  @Prop({ type: Boolean, required: false, default: false })
  secondAccordion?: boolean;

  @Inject('accordion')
  private injectedValue!: { count: number; active: number | null };

  data() {
    return {
      index: null,
    };
  }

  created() {
    this.$data.index = this.injectedValue.count++;
  }

  render() {
    return (
      <li
        class={{ [style.accordionItem]: true, [style.productAccordionStyle]: this.productAccordionStyle }}
        key={'accordion-item-' + this.$data.index}>
        <div
          onClick={(ev: Event) => this.open(ev)}
          class={{
            [style.accordionTrigger]: true,
            [style.accordionTriggerActive]: this.isVisible,
            [style.secondAccordion]: this.secondAccordion,
          }}
          key='accordion-header'>
          {/* This slot will handle the title/header of the accordion and is the part you click on */}
          <slot name='accordionTrigger' />
        </div>

        {this.hideOnCollapse ? (
          <div v-show={this.isVisible} class={style.accordionContent}>
            {/* This slot will handle all the content that is passed to the accordion */}
            <slot name='accordionContent' />
          </div>
        ) : null}
      </li>
    );
  }

  private get isVisible() {
    return this.$data.index === this.injectedValue.active;
  }

  private open(ev) {
    setTimeout(() => {
      const section = ev.srcElement ?? ev.currentTarget;
      section.scrollIntoView({ behavior: 'smooth', block: !this.isDesktop ? 'start' : 'center', inline: 'nearest' });
    }, 10);

    if (this.isVisible) {
      this.injectedValue.active = null;
    } else {
      this.injectedValue.active = this.$data.index;
    }
  }
}
