import { Component, BaseComponent, Prop, Provide } from '@zento-lib/components';

import type { IAccordion } from './Accordion.d';
import style from './style.scss';

@Component({})
export class Accordion extends BaseComponent<IAccordion, unknown> {
  /**
   * Determines which tab is by default opened
   */
  @Prop({ type: Number, required: false, default: null })
  activeTab?: number | null;

  @Provide('accordion')
  protected providedValue: { count: number; active: number | null } = { count: 0, active: this.activeTab };

  render() {
    return (
      <ul class={style.accordion}>
        <slot></slot>
      </ul>
    );
  }
}
